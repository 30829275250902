import React, {useEffect, useState} from "react";
import {graphql} from "gatsby";
import Layout from "../components/layout";
import {Alert, Button, Col, Container, Form, Row} from "react-bootstrap";
import globalStyle from "../styles/global.module.css";
import headshotsAndBrandingStyle from "./headshots-and-branding-experience.module.css";
import addToMailchimp from "gatsby-plugin-mailchimp";
import env from "../config/env";
import santaPhotosStyle from "./socially-distant-santa-photos.module.css";
import becomeAMemberStyle from "./become-a-member.module.css";
import HeadshotsAndBrandingExperienceSkuCard from "../components/Products/headshotsAndBrandingExperienceSkuCard";

const HeadshotsAndBrandingExperiencePage = ({data}) => {
  const submitHeadshotsUrl = env.submitHeadshotsUrl;

  const [showFieldsForm, setShowFieldsForm] = useState( false);
  const [hideBookNowButton, setHideBookNowButton] = useState( false);
  const [stripeErrorMessage, setStripeErrorMessage] = useState({ show: false, message: "" });
  const [mailchimpAddMemberShowError, setMailchimpAddMemberShowErrorAlert] = useState({ show: false, message: "" });
  const [formResult, setFormResult] = useState({});
  const [sku, setSku] = useState(null);
  const [showEmailErrorMessage, setShowEmailErrorMessage] = useState({ show: false, message: "" });
  const [showNameErrorMessage, setShowNameErrorMessage] = useState({ show: false, message: "" });
  const [showDateTimeErrorMessage, setShowDateTimeErrorMessage] = useState({ show: false, message: "" });
  const [showPackageTypeErrorMessage, setShowPackageTypeErrorMessage] = useState({ show: false, message: "" });

  useEffect(() => {
    updateSku();
  }, );

  const packages = {
    'Package A': {
      price: 69.00,
    },
    'Package B': {
      price: 109.00,
    }
  }

  const [formState, setFormValues] = useState({
    firstDate: "",
    firstHour: "",
    secondDate: "",
    secondHour: "",
    quantity: 1,
    phone: "",
    email: "",
    name: "",
    packageType: ""
  });

  const handleHeadshotsFormSubmit = async e => {
    e.preventDefault();
    try {

    } catch (error) {
      console.log(error);
    }
  };

  const handleHeadshotsFormChange = e => {
    setFormValues({
      ...formState,
      [e.target.name]: e.target.value,
    })
  };

  const showStripeErrorMessage = showStripeErrorMessage => {
    setStripeErrorMessage({
      show: true,
      message: showStripeErrorMessage,
    })
  };

  const validateChoicesForm = () => {
    let isValid = true;

    if (!formState.firstDate || !formState.firstHour) {
      isValid = false;
      setShowDateTimeErrorMessage({
        show: true,
        message: "Please select at least one date and one hour!"
      })
    }

    if (formState.secondDate && !formState.secondHour || formState.secondHour && !formState.secondDate) {
      isValid = false;

      setShowDateTimeErrorMessage({
        show: true,
        message: "Please select at least one date!"
      })
    }

    if (!formState.packageType) {
      isValid = false;
      setShowPackageTypeErrorMessage({
        show: true,
        message: "Please select a package!"
      })
    }
    return isValid
  };

  const validateUserDetailsForm = () => {
    let isValid = true;

    if (!formState.email) {
      isValid = false;
      setShowEmailErrorMessage({
        show: true,
        message: "Please enter your email address!"
      })
    }

    if (!formState.name) {
      isValid = false;
      setShowNameErrorMessage({
        show: true,
        message: "Please enter your name!"
      })
    }

    if (!formState.packageType) {
      isValid = false;
      setShowPackageTypeErrorMessage({
        show: true,
        message: "Please choose a package!"
      })
    }

    return isValid;
  };

  const saveBookingToMailchimp = async () => {
    const result = await addToMailchimp(formState.email, {
        FIRSTDATE: formState.firstDate,
        FIRSTHOUR: formState.firstHour,
        SECONDDATE: formState.secondDate,
        SECONDHOUR: formState.secondHour,
        PHONE: formState.phone,
        NAME: formState.name,
        PACKAGETYP: formState.packageType
      }, submitHeadshotsUrl
    );
    setFormResult(result);
    return true;
  };

  const validateAndSaveData = async () => {
    return validateUserDetailsForm() && await saveBookingToMailchimp();
  };

  const updateSku = () => {
    if(formState.packageType === "Package A") {
      data.skus.edges.map(edge => {
        let productName = "Headshots and Branding Experience Package A";
        if (productName === edge.node.product.name) {
          setSku(edge.node);
        }
      })
    } else if(formState.packageType === "Package B"){
      data.skus.edges.map(edge => {
        let productName = "Headshots and Branding Experience Package B";
        if (productName === edge.node.product.name) {
          setSku(edge.node);
        }
      });
    }
  };


  const onBookNow = async () => {
    updateSku();
    const isValid = validateChoicesForm();
    if (!isValid) {
      return;
    }

    setShowFieldsForm({
      showFieldsForm: true
    });
    setHideBookNowButton({
      hideBookNowButton:false
    });
  };

  return (
    <Layout>
      <Container>

        <div>
          <div className="title">
            <h1 className={globalStyle.entryTitle}>Professional Photos of You, Yes Please! Join us for Your Private Headshots and Branding Experience</h1>
            <p className={globalStyle.boldStyle}>With Moms on Maternity and
              <a className={globalStyle.redirectLink}
                 href="http://www.photosbychloe.com/" target="_blank"
                 rel="noopener noreferrer"> Chloe Atnip Photography</a>
            </p>
          </div>

          <Row className={headshotsAndBrandingStyle.rowStyle}>
            <Col xs={12} md={6} sm={12}>
              <Row>
                <Col>
                  <video width="100%" height="auto" autoPlay loop muted playsInline>
                    <source src={data.headshotsIntro.publicURL} type="video/mp4"/>
                  </video>
                </Col>
              </Row>
            </Col>
            <Col xs={12} md={6} sm={6}>
              <div className={headshotsAndBrandingStyle.about}>
                <h5><i className={[headshotsAndBrandingStyle.icon, "fa fa-camera"].join(" ")} aria-hidden="true"></i>
                   Mama’s are you in the mood for some professional headshots and branding photos?
                </h5>
                <h5><i className={[headshotsAndBrandingStyle.icon, "fab fa-linkedin"].join(" ")} aria-hidden="true"></i> Are you ready to update your LinkedIn?</h5>
                <h5><i className={[headshotsAndBrandingStyle.icon, "fas fa-photo-video"].join(" ")}></i> And other Social Media Profile Pictures?</h5>
                <h5><i className={[headshotsAndBrandingStyle.icon, "fas fa-portrait"].join(" ")}></i> Update your personal image online!</h5>
                <h5><i className={[headshotsAndBrandingStyle.icon, "fas fa-portrait"].join(" ")}></i> Update your website and marketing collateral with your latest look today!</h5>
              </div>
              <div className={headshotsAndBrandingStyle.nextRow}>
                <i className="fa fa-angle-double-down"></i>
              </div>
            </Col>
          </Row>

          <Row className={headshotsAndBrandingStyle.rowStyle}>
            <Col xs={12} md={6} sm={6}>
              <div className={headshotsAndBrandingStyle.periodTitle}>
                <div className={[headshotsAndBrandingStyle.dateContent, headshotsAndBrandingStyle.eventDate].join(' ')}>
                  <h4 >Two Days:</h4>
                </div>
                <div className={headshotsAndBrandingStyle.dateContent}>
                  <i className={[headshotsAndBrandingStyle.icon, "fa fa-calendar"].join(" ")} aria-hidden="true">
                    <span className={headshotsAndBrandingStyle.eventDate}>Tuesday March 9th</span>
                  </i>
                </div>
                <div className={headshotsAndBrandingStyle.dateContent}>
                  <i className={[headshotsAndBrandingStyle.icon, "fa fa-calendar"].join(" ")} aria-hidden="true">
                    <span className={headshotsAndBrandingStyle.eventDate}>Wednesday March 10th </span>
                  </i>
                </div>

                <div className={headshotsAndBrandingStyle.dateContent}>
                  <span className={headshotsAndBrandingStyle.eventDate}>in San Diego</span>
                </div>

              </div>

              <div className={headshotsAndBrandingStyle.nextRow}>
                <i className="fa fa-angle-double-down"></i>
              </div>
            </Col>
            <Col xs={12} md={6} sm={6}>
              <video width="100%" height="auto" autoPlay loop muted playsInline>
                <source src={data.headshotsExamples.publicURL} type="video/mp4"/>
              </video>
            </Col>
          </Row>

          <Row className={headshotsAndBrandingStyle.rowStyle}>
            <Col xs={12} md={6} sm={6}>
              <video width="100%" height="auto" autoPlay loop muted playsInline>
                {/*<source src={data.headshotsExamplesBottom.publicURL} type="video/mp4"/>*/}
              </video>

              <div className={headshotsAndBrandingStyle.nextRow}>
                <i className="fa fa-angle-double-down"></i>
              </div>
            </Col>

            <Col xs={12} md={6} sm={6}>
              <ul className={headshotsAndBrandingStyle.aboutDetails}>
                <li>We have found that when you update your personal image online it indicates you’re here and you’re
                  serious about what you are doing!
                </li>

                <li>Use new, stunning headshots to showcase you, your values, passions and services.</li>

                <li>Update your website and marketing collateral with your latest look today.</li>

                <li>Our professional photography team led by the talented Chloe Atnip has been handpicked.
                  Chloe is shooting for us in <span className={headshotsAndBrandingStyle.aboutInfo}>San Diego</span> on
                  <span className={headshotsAndBrandingStyle.aboutInfo}> March 9th and 10th</span>. Chloe has her own studios currently in
                  Las Vegas and Orange County and she is setting up a pop-up professional studio just for
                  Moms on Maternity and YOU.</li>

                <li>Chloe is an expert in posing and lighting and will make sure you Love your headshots and branding
                  photos.</li>

                <li>Here are examples of her work with Moms on Maternity Founder Aimee Cruz plus some of her other clients.</li>
              </ul>
            </Col>
          </Row>

          <Row className={headshotsAndBrandingStyle.rowStyle} id="bookNowContainer">
            <Col xs={12} md={6} sm={6}>

              <Row className={headshotsAndBrandingStyle.bannerContent}>
                <Col className={headshotsAndBrandingStyle.bannerColumn}>
                  <Row className={headshotsAndBrandingStyle.price}>
                    <Col>
                      <p> Book now! </p>
                    </Col>
                  </Row>

                  <Row className={headshotsAndBrandingStyle.memberBenefitsVideo}>
                    <h4 className={headshotsAndBrandingStyle.description}>
                      Choose your desired window for your Headshots and Branding Experience
                    </h4>
                  </Row>

                  <Form
                    onSubmit={handleHeadshotsFormSubmit}
                    name="registerPhotosWithSanta">

                    <Form.Row>
                      <h5>Select your first choice<span className={globalStyle.required}></span></h5>
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formFirstDate" className={headshotsAndBrandingStyle.registerFormGroup}>
                          <Form.Control as="select"
                                        onChange={handleHeadshotsFormChange}
                                        value={formState.firstDate}
                                        placeholder="Select your first choice" name="firstDate">
                            <option className="defaultValue" value="">Select date</option>
                            <option>09 March</option>
                            <option>10 March</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formFirstHour" className={headshotsAndBrandingStyle.registerFormGroup}>
                          <Form.Control as="select"
                                        onChange={handleHeadshotsFormChange}
                                        onClose={() => setShowDateTimeErrorMessage({ show: false })}
                                        value={formState.firstHour}
                                        placeholder="Select your first choice" name="firstHour">
                            <option className="defaultValue" value="">Select hour</option>
                            <option>9:00-9:30</option>
                            <option>9:30-10:00</option>
                            <option>10:00-10:30</option>
                            <option>10:30-11:00</option>
                            <option>11:00-11:30</option>
                            <option>11:30-12:00</option>
                            <option>12:00-12:30</option>
                            <option>12:30-13:00</option>
                            <option>13:00-13:30</option>
                            <option>13:30-14:00</option>
                            <option>14:00-14:30</option>
                            <option>14:30-15:00</option>
                            <option>15:00-15:30</option>
                            <option>15:30-16:00</option>
                            <option>15:00-16:30</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={12} sm={12}>
                        {showDateTimeErrorMessage.show && (
                          <Row className={becomeAMemberStyle.showError}>
                            <Col>
                              <Alert
                                variant="danger"
                                onClose={() => setShowDateTimeErrorMessage({ show: false })}
                                dismissible
                              >
                                {showDateTimeErrorMessage.message}
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      <h5>Select your second choice</h5>
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formSecondDate" className={headshotsAndBrandingStyle.registerFormGroup}>
                          <Form.Control as="select"
                                        onChange={handleHeadshotsFormChange}
                                        value={formState.secondDate}
                                        placeholder="Select your first choice" name="secondDate">
                            <option className="defaultValue" value="">Select date</option>
                            <option>09 March</option>
                            <option>10 March</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formSecondHour" className={headshotsAndBrandingStyle.registerFormGroup}>
                          <Form.Control as="select"
                                        onChange={handleHeadshotsFormChange}
                                        value={formState.secondHour}
                                        placeholder="Select your first choice" name="secondHour">
                            <option className="defaultValue" value="">Select hour</option>
                            <option>9:00-9:30</option>
                            <option>9:30-10:00</option>
                            <option>10:00-10:30</option>
                            <option>10:30-11:00</option>
                            <option>11:00-11:30</option>
                            <option>11:30-12:00</option>
                            <option>12:00-12:30</option>
                            <option>12:30-13:00</option>
                            <option>13:00-13:30</option>
                            <option>13:30-14:00</option>
                            <option>14:00-14:30</option>
                            <option>14:30-15:00</option>
                            <option>15:00-15:30</option>
                            <option>15:30-16:00</option>
                            <option>15:00-16:30</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Row>
                        <Col>
                          <p className={headshotsAndBrandingStyle.noteContent}>
                            *We will confirm your session with 24 hours of your booking. In your confirmation, we will
                            include the shoot location. Parking is easy and free. On your booking confirmation we will
                            include, tips for photoshoot clothing, jewelry and posing. We are excited to help you shine
                            and look forward to seeing you soon!
                          </p>
                        </Col>
                      </Row>

                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formPackageType" className={headshotsAndBrandingStyle.registerFormGroup}>
                          <Form.Control as="select"
                                        onChange={handleHeadshotsFormChange}
                                        value={formState.packageType}
                                        placeholder="Select your package" name="packageType"
                                        onClick={() => setShowPackageTypeErrorMessage({ show: false })}>
                            <option className="defaultValue" value="">
                              Select package</option>
                            <option value="Package A">Package A</option>
                            <option value="Package B">Package B</option>
                          </Form.Control>
                        </Form.Group>
                      </Col>

                      <Row className="d-block d-sm-none">
                        <Col>
                          <p>*See package details below</p>
                        </Col>
                      </Row>

                      <Col xs={12} md={12} sm={12}>
                        {showPackageTypeErrorMessage.show && (
                          <Row className={becomeAMemberStyle.showError}>
                            <Col>
                              <Alert
                                variant="danger"
                                onClose={() => setShowPackageTypeErrorMessage({ show: false })}
                                dismissible
                              >
                                {showPackageTypeErrorMessage.message}
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      {!hideBookNowButton &&
                      <Button
                        className={[
                          globalStyle.btnPrimary,
                          headshotsAndBrandingStyle.goldButton,
                          headshotsAndBrandingStyle.bookNowButton
                        ]}
                        onClick={onBookNow}
                      >
                        BOOK NOW!
                      </Button>
                      }

                      {showFieldsForm &&
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formName" className={[headshotsAndBrandingStyle.registerFormGroup, headshotsAndBrandingStyle.formLabel]}>
                          <Form.Label >Name <span className={globalStyle.required}></span></Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.name}
                            name="name"
                            type="text"
                            placeholder="Please enter your name"
                            onChange={handleHeadshotsFormChange}
                            onClick={() => setShowNameErrorMessage({ show: false })}
                          />
                        </Form.Group>
                      </Col>
                      }

                      <Col xs={12} md={12} sm={12}>
                        {showNameErrorMessage.show && (
                          <Row className={becomeAMemberStyle.showError}>
                            <Col>
                              <Alert
                                variant="danger"
                                onClose={() => setShowNameErrorMessage({ show: false })}
                                dismissible
                              >
                                {showNameErrorMessage.message}
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      {showFieldsForm &&
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formEmail" className={[headshotsAndBrandingStyle.registerFormGroup, headshotsAndBrandingStyle.formLabel]}>
                          <Form.Label >Email <span className={globalStyle.required}></span></Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.email}
                            name="email"
                            type="text"
                            placeholder="Please enter your email"
                            onChange={handleHeadshotsFormChange}
                            onClick={() => setShowEmailErrorMessage({ show: false })}
                          />
                        </Form.Group>
                      </Col>
                      }

                      <Col xs={12} md={12} sm={12}>
                        {showEmailErrorMessage.show && (
                          <Row className={becomeAMemberStyle.showError}>
                            <Col>
                              <Alert
                                variant="danger"
                                onClose={() => setShowEmailErrorMessage({ show: false })}
                                dismissible
                              >
                                {showEmailErrorMessage.message}
                              </Alert>
                            </Col>
                          </Row>
                        )}
                      </Col>

                      {showFieldsForm &&
                      <Col xs={12} md={12} sm={12}>
                        <Form.Group as={Col} controlId="formPhone" className={[headshotsAndBrandingStyle.registerFormGroup, headshotsAndBrandingStyle.formLabel]}>
                          <Form.Label >Phone </Form.Label>
                          <Form.Control
                            size="sm"
                            value={formState.phone}
                            name="phone"
                            type="text"
                            placeholder="Please enter your phone number"
                            onChange={handleHeadshotsFormChange}
                          />
                        </Form.Group>
                      </Col>
                      }

                      { showFieldsForm &&
                      <Col xs={12} md={12} sm={12} className={headshotsAndBrandingStyle.checkoutButton}>
                        <HeadshotsAndBrandingExperienceSkuCard
                          // price={() => {console.log(formState.packageType)}}
                          price={formState.packageType ? packages[formState.packageType].price : ''}
                          quantity={formState.quantity}
                          email={formState.email}
                          sku={sku}
                          beforeRedirect={validateAndSaveData}
                          showStripeErrorMessage={showStripeErrorMessage}
                          path="/headshots-and-branding-experience"
                          // discountCodeIsValid={discountCodeIsValid}
                        />
                      </Col>
                      }

                    </Form.Row>
                  </Form>

                  <Row className={headshotsAndBrandingStyle.price}>
                    <Col>
                      <p>{formState.packageType && <span>${packages[formState.packageType].price}</span>} Get Your Private Headshots and Branding Experience</p>
                    </Col>
                  </Row>
                </Col>
              </Row>

            </Col>

            <Col xs={12} md={6} sm={6}>
              <div className={headshotsAndBrandingStyle.periodTitle}>
                <h4 style={{"marginTop": "1rem"}}>Two packages:</h4>
              </div>

              <Row>
                <Col xs={12} md={6} sm={6} className={headshotsAndBrandingStyle.packageColumn}>
                  <p className={headshotsAndBrandingStyle.packageTitle}>Package A</p>
                  <ul className={headshotsAndBrandingStyle.packageList}>
                    <li><span>2 outfits</span></li>
                    <li><span>2 backdrops</span> (light, medium or dark options</li>
                    <li><span>Standing and sitting poses</span></li>
                    <li><span>2 edited photos</span> of your choice included; additional photos may be purchased if desired* </li>
                    <li><span>Real time viewing and selection</span> of your favorite and best photos during shoot. Final, edited photos delivered within 5-7 business days </li>
                  </ul>

                  <Row>
                    <Col className={headshotsAndBrandingStyle.packageTitle}>
                      <h4 className={headshotsAndBrandingStyle.packageMessage}>Get excited!</h4>
                      <p className={headshotsAndBrandingStyle.priceStyle}>$69.00</p>
                      <p className={headshotsAndBrandingStyle.timeDetails}>45 minutes</p>
                    </Col>
                  </Row>

                </Col>
                <Col xs={12} md={6} sm={6} className={headshotsAndBrandingStyle.packageColumn}>
                  <p className={headshotsAndBrandingStyle.packageTitle}>Package B</p>
                  <ul className={headshotsAndBrandingStyle.packageList}>
                    <li><span>4 outfits</span></li>
                    <li><span>3 backdrops</span> (light, medium or dark options</li>
                    <li><span>Standing and sitting poses</span></li>
                    <li><span>4 edited photos</span> of your choice included; additional photos may be purchased if desired* </li>
                    <li><span>Real time viewing and selection</span> of your favorite and best photos during shoot. Final, edited photos delivered within 5-7 business days </li>
                  </ul>

                  <Row>
                    <Col className={headshotsAndBrandingStyle.packageTitle}>
                      <h4 className={headshotsAndBrandingStyle.packageMessage}>Get excited!</h4>
                      <p className={headshotsAndBrandingStyle.priceStyle}>$109.00</p>
                      <p className={headshotsAndBrandingStyle.timeDetails}>75 minutes</p>
                    </Col>
                  </Row>

                </Col>
              </Row>

              <Row>
                <Col>
                  <span className={headshotsAndBrandingStyle.additionalInfo}>*
                    additional photos may be purchased if desired at $30 per photo </span>
                </Col>
              </Row>

              <Row className="d-block d-sm-none">
                <Col className="text-center">
                  <a href="#bookNowContainer"><Button
                    className={[
                      globalStyle.btnPrimary,
                      headshotsAndBrandingStyle.goldButton,
                      headshotsAndBrandingStyle.bookNowButton
                    ]}
                  >BOOK NOW!</Button></a>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className={headshotsAndBrandingStyle.rowStyle}>
            <Col>
              <h4>Thank you to our MOM Headshot and Branding Experience Partners:</h4>
            </Col>
          </Row>

          <Row>
            <Col xs={12} md={6} sm={6}>
              <ul>
                <li className={headshotsAndBrandingStyle.sponsorsItem}>
                  <a className={headshotsAndBrandingStyle.sponsors} href="http://www.joseebrisebois.com/"
                     target="_blank" rel="noopener noreferrer">Josee Brisebois - personal stylist</a>
                </li>
              </ul>
            </Col>

            <Col xs={12} md={6} sm={6}>
              <ul>
                <li className={headshotsAndBrandingStyle.sponsorsItem}>
                  <a className={headshotsAndBrandingStyle.sponsors} href="https://www.parravijewelry.com/"
                     target="_blank" rel="noopener noreferrer">Parravi Jewelry - jewelry design </a>
                </li>
              </ul>
            </Col>
          </Row>

          <Row className={santaPhotosStyle.rowStyle}>
            <Col>
              <p>
                <span className={globalStyle.required}></span>
                <span className={globalStyle.required}></span>
                <span className={globalStyle.required}></span>
                COVID Information: We are committed to ensuring adherence to safety protocols. Your health is our utmost
                priority and concern. Must have an appointment to enter photo studio. Thank you!</p>
            </Col>
          </Row>

        </div>
      </Container>
    </Layout>
  )
};

export const query = graphql`
  query {
    arrowDown: file(
      relativePath: { eq: "santa-photos/arrow-down.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 1800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    headshotsIntro: file(
      relativePath: {eq: "headshots-and-branding-experience/headshots-intro.mp4"}
    ) {
        publicURL
        name
    } 
    headshotsExamples: file(
      relativePath: {eq: "headshots-and-branding-experience/headshots-examples.mp4"}
    ) {
        publicURL
        name
    } 
    headshotsExamplesBottom: file(
      relativePath: {eq: "headshots-and-branding-experience/headshots-examples-bottom.mp4"}
    ) {
        publicURL
        name
    }
    skus: allStripeSku {
      edges {
        node {
          id
          price
          attributes {
            size
          }
          product {
            id
            name
          }
        }
      }
    }
  }
`;

export default HeadshotsAndBrandingExperiencePage;
