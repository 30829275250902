import React from "react";
import globalStyle from "../../styles/global.module.css";
import {Button} from "react-bootstrap";
import env from "../../config/env";

class HeadshotsAndBrandingExperienceSkuCard extends React.Component {

  constructor(props, data) {
    super(props);
    this.state = {
      setFormResult: {},
      stripe: null,
      sku: this.props.sku,
      formName: this.props.formName,
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.state.sku = this.props.sku;
  }

  componentDidMount() {
    const stripe = window.Stripe(env.GATSBY_STRIPE_PUBLIC_KEY);
    this.setState({ stripe });
  }

  async redirectToCheckout(event, quantity = 1) {
    event.preventDefault();

    try {
      quantity = parseInt(this.props.quantity) || 1;
      const path = this.props.path;

      let shouldRedirect = true;

      if (this.props.beforeRedirect) {
        shouldRedirect = await this.props.beforeRedirect();
      }

      if (!shouldRedirect) {
        return;
      }

      const sku = this.props.sku ? this.props.sku.id : "";

      const resp = await this.state.stripe.redirectToCheckout({
        items: [{sku, quantity}],
        customerEmail: this.props.email,
        successUrl: env.STRIPE_HEADSHOTS_AND_BRANDING_EXPERIENCE_SUCCESS_URL,
        cancelUrl: window ? window.location.protocol + "//" + window.location.host + path : "",
        billingAddressCollection: "required",
        shippingAddressCollection: {
          allowedCountries: ["US"]
        }
      });

      if (resp.error) {
        console.warn("Error:", resp.error)
      }
    } catch (error) {

      if (error) {
        this.props.showStripeErrorMessage(error.message);
        return
      }
    }
  }

  render() {
    return (
      <div className="rest">
        <Button
          type="submit"
          className={[globalStyle.btnPrimary, globalStyle.submitButtonOrange]}
          onClick={event => this.redirectToCheckout(event)}
        >BUY FOR ${this.props.price}</Button>
      </div>
    )
  }
}

export default HeadshotsAndBrandingExperienceSkuCard;
